import './index.css';
import L4 from './pages/L4'
import {Helmet} from "react-helmet";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Cel mai dificil puzzle din 2 piese</title>
      </Helmet>
      <L4/>
    </div>
  );
}

export default App;
