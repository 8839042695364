import React, {useState} from 'react';

export default function L4() {

    const [openImage, setOpenImage] = useState(false);

    const handleBtnClick = () => {
        setOpenImage(!openImage);
    }

    return (
        <>
        <h1 className='title'>
        Soluția pentru "Cel mai dificil puzzle din 2 piese" L4
        </h1>
        <div className='main'>
            <h2>Felicitări!</h2>
            <p>
                Ai ajuns aici pentru că ai acceptat provocarea “Cel mai dificil puzzle din 2 piese”. Dacă ai încercat și încă nu ai găsit soluția, nu te descuraja. Uneori, rezolvările cele mai simple sunt cele care ne pun cel mai mult la încercare.
            </p>
            <h2>Soluția este în simetrie.</h2>
            <p>
             Cele două piese trebuie așezate astfel încât să formeze o formă perfect simetrică. Secretul constă în a găsi unde poate fi axa de simetrie față de care trebuie să poziționezi cele două piese astfel încât să le aliniezi de-a lungul acestei linii imaginare. Secretul este să găsești echilibrul perfect, folosind axa de simetrie pentru a ghida poziția pieselor.
            </p>
            <p>
                Dacă nu vrei să mai încerci, apasă butonul de mai jos:
            </p>
            <button className='btn' onClick={handleBtnClick}>
                Vreau sa vad soluția
            </button>
            {openImage && (
                <div className='solution-img'>
                    <img src="/assets/l4Solution.png" alt="Solution" />
                </div>
            )}
            <h2>
                Considerăm că acest joc de logică este foarte dificil, așa că te felicităm sincer dacă ai reușit să-l rezolvi! Iar dacă nu, nu te descuraja – îți recomandăm să încerci și una dintre versiunile mai ușoare disponibile pentru a-ți antrena abilitățile logice.
            </h2>
        </div>
        </>
    );
}